import React, { Component } from "react"
import { navigate } from "gatsby"
import styled from "styled-components"
import { LINKS } from "../constants/links"

const StyledQrCodeWrapper = styled.div`
  .qr-code-reader {
    > section {    
      > div {
        box-shadow: rgb(63, 81, 181) 0px 0px 0px 5px inset !important;
      }
    }
  }`

class QRScanner extends Component {
  QrReader = null

  state = {
    qrReaderScanner: false,
  }

  componentDidMount() {
    if (typeof window !== `undefined`) {
      this.QrReader = require("react-qr-reader")
      this.setState({ qrReaderScanner: true })
    }
  }

  handleScan = data => {
    if (!data) {
      return
    }
    navigate(LINKS.PAYMENT)
  }

  handleError = err => {
    console.error(err)
  }

  render() {
    const QrReader = this.QrReader
    return (
      <StyledQrCodeWrapper>
        {QrReader && <QrReader delay={300}
                               onError={this.handleError}
                               onScan={this.handleScan}
                               className={"qr-code-reader"}/>}
      </StyledQrCodeWrapper>
    )
  }
}

export default QRScanner