import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import QRScanner from "../components/QRScanner"

const IndexPage = () => (
  <Layout>
    <SEO title="Home"/>
    <QRScanner/>
  </Layout>
)

export default IndexPage
